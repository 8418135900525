<template>
  <div>
    <h4 class="text-3xl sm:text-4.5xl font-bold text-center">Congrats on Your <br> First MINIVIEW™! 🎉</h4>
    <p class="text-lg font-roboto text-grayDark my-9 text-center">At Guestio, MINIVIEWs™ can have MEGA-payouts.</p>

    <div class="px-10 py-6 flex items-center justify-center bg-violet bg-opacity-10 text-violet text-center">
      More opportunities await when you claim your free Guestio account today!
    </div>

    <div class="flex justify-center space-x-3 mt-14">
      <router-link
        class="bg-indigo-gradiant text-white font-bold px-8 py-4 rounded-full flex items-center justify-center hover:opacity-90"
        :to="{ name: 'register' }"
      >
        Create Your Account
      </router-link>
      <a href="https://guestio.com/pricing/" class="bg-white text-violet border border-violet px-8 py-4 rounded-full flex items-center justify-center">More Information</a>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "AnswerSubmitted",

  computed: {
    ...mapGetters({
      answerForm: 'miniviews/answerForm',
    }),
  },

  mounted() {
    if (this.answerForm.name == '' || this.answerForm.answers.length == 0) {
      this.$router.push({name: 'MiniviewAnswerName' })
    }
  },
};
</script>
